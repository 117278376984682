import { Input, Popover } from 'antd';
import type { InputProps } from 'antd';
import { connect, mapProps } from '@formily/react';
import { Field } from '@formily/core';
import { useBoolean } from 'ahooks';
import { IconPanel } from '../../Icons';

interface IProps extends Omit<InputProps, 'onInput'> {
  onInput: (value: string) => void;
}

function BasicInputIcon(props: IProps) {
  const [visible, { setTrue: setShow, setFalse: setHide }] = useBoolean(false);
  const { onInput, ...extra } = props;

  const onVisibleChange = (value: boolean) => {
    if (!value) setHide();
  };

  return (
    <Popover
      open={visible}
      onOpenChange={onVisibleChange}
      content={
        <IconPanel
          onSelect={(value: string) => {
            onInput(value);
            setHide();
          }}
        />
      }
      trigger="click">
      <Input
        {...extra}
        readOnly
        onClick={(e) => {
          e.stopPropagation();
          setShow();
        }}
      />
    </Popover>
  );
}

export const InputIcon = connect(
  BasicInputIcon,
  mapProps((props, field) => {
    return {
      ...props,
      value: props.value || (field as Field).value,
      onInput: (field as Field).onInput,
    };
  }),
);
